import React from "react";
import Feed from "./Feed/Feed.js";

const Home = () => {
  return (
    <section className="container mainContainer">
      <Feed />
    </section>
  );
};

export default Home;
